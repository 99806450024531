<template>
  <Form
    :hide-reset="edit"
    :loading="loading"
    @submit="onSubmit"
    @reset="onReset"
  >
    <v-row dense>
      <FormItem
        v-model="name"
        rules="required"
        name="Nome"
        :cols="9"
        :md="6"
        :lg="6"
        :xl="6"
        dense
      />
    </v-row>
    <v-row dense>
      <FormAddress
        v-if="!edit"
        v-model="address"
        rules="required"
        name="Indirizzo"
        :cols="12"
        :md="10"
        :lg="8"
        :xl="8"
        dense
        @address="setCurrentValues"
      />
      <FormItem
        v-else
        v-model="address"
        rules="required"
        name="Indirizzo"
        :cols="12"
        :md="10"
        :lg="8"
        :xl="8"
        dense
      />
    </v-row>
    <v-row dense>
      <FormItem
        v-model="city"
        name="Città"
        :cols="4"
        :md="3"
        dense
        rules="required"
      />
      <FormItem
        v-model="cap"
        name="CAP"
        :cols="2"
        :md="2"
        dense
        rules="required"
      />
    </v-row>
    <v-row dense>
      <FormRegions
        v-model="region"
        :cols="6"
        :md="4"
        :lg="4"
        :xl="4"
        dense
        rules="required"
      />
      <FormProvince
        v-model="province"
        rules="required"
        :regions="region"
        :cols="6"
        :md="4"
        :lg="4"
        :xl="4"
        dense
      />

      <FormItem
        v-model="lat"
        rules="required"
        name="Lat"
        :cols="2"
        :sm="3"
        :md="2"
        :lg="1"
        dense
      />
      <FormItem
        v-model="lng"
        rules="required"
        name="Long"
        :cols="2"
        :sm="2"
        :md="2"
        :lg="1"
        dense
      />
    </v-row>
    <v-row dense>
      <ChipsSelect v-model="tags" dense />
    </v-row>
    <v-row dense>
      <v-col cols="12" :md="7" :lg="6" :xl="7">
        <ChipsSelect
          v-model="distribution_list"
          label="Notifiche Ticket Via Mail"
          hint="Inserici gli indirizzi ai quali verrà inviata una notifica in caso di nuovi ticket."
          :rules="{ required: false, email: true }"
        />
      </v-col>
    </v-row>
    <h4>Note</h4>
    <v-row dense>
      <FormItem
        v-model="notes"
        name="note"
        type="textarea"
        :cols="10"
        :md="9"
        :lg="9"
        dense
      />
    </v-row>
  </Form>
</template>

<script>
import { createHelpers } from 'vuex-map-fields'
import { mapActions, mapMutations, mapState } from 'vuex'

import Form from '@components/forms/Form.vue'
import FormItem from '@components/forms/FormItem.vue'
import FormRegions from '@components/forms/FormRegions.vue'
import FormProvince from '@components/forms/FormProvince.vue'
import FormAddress from '@components/forms/FormAddress.vue'
import ChipsSelect from '@components/forms/ChipsSelect.vue'

const { mapFields } = createHelpers({
  getterType: 'customersLocations/getCurrent',
  mutationType: 'customersLocations/SET_CURRENT_FIELDS',
})

export default {
  name: 'CustomerLocationsForm',
  components: {
    FormAddress,
    FormProvince,
    FormRegions,
    FormItem,
    Form,
    ChipsSelect,
  },
  props: {
    edit: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: 'Crea Nuovo',
    },
  },
  computed: {
    ...mapFields([
      'name',
      'piva_cfisc',
      'address',
      'city',
      'region',
      'province',
      'cap',
      'country',
      'lat',
      'lng',
      'notes',
      'tags',
      'distribution_list',
    ]),
    ...mapState('customersLocations', {
      id: (state) => state.current.id,
      loading: (state) => state.loading,
    }),
  },
  methods: {
    ...mapMutations('customersLocations', {
      reset: 'RESET_CURRENT',
      setCurrentValues: 'SET_CURRENT',
    }),
    ...mapActions('customersLocations', ['create', 'update']),
    async onSubmit() {
      this.$emit('submit')

      if (!this.edit) {
        await this.create()
      } else {
        await this.update()
      }

      this.$emit('submitted', this.id)
    },
    onReset() {
      this.reset()
      this.$emit('resetted')
    },
  },
}
</script>

<style scoped></style>
