<template>
  <Form
    :hide-reset="edit"
    :loading="loading"
    :disabled="isInvalidAssociatedUserLocation || isAssociatedEqualCurrent || isAssociatedEqualReplacement"
    @submit="onSubmit"
    @reset="onReset"
  >
    <v-row>
      <FormCustomers
        v-if="!hideCustomer"
        v-model="customer_id"
        :customer_id="customer_id"
        name="Seleziona Cliente"
        :rules="{ required: !file }"
        :disabled="!!file"
        :lg="3"
      />
      <CustomersSubscriptionsSelect
        v-model="plans_subscription_id"
        :customerid="customer_id"
        name="Sottoscrizione"
        :rules="{ required: !file }"
        :disabled="!!file"
        :lg="3"
      />
      <CustomersLocationsSelect
        v-model="customer_location_id"
        :customerid="customer_id"
        name="Sede"
        clearable
        :disabled="!!file"
        :lg="3"
      />

      <v-col :cols="12" :md="6" :lg="3">
        <v-row dense>
          <FormCustomersUsers
            ref="contattoUsersSelect"
            v-model="customer_user_id"
            :customer_id="customer_id"
            :customer_user_id="customer_user_id"
            :disabled="!customer_id"
            name="Contatto"
            clearable
            :lg="customer_id && !customer_user_id ? 9 : 12"
            :xl="customer_id && !customer_user_id ? 9 : 12"
          />
          <v-col v-show="customer_id && !customer_user_id" :cols="3">
            <CustomerUsersBtn
              :customer_id="customer_id"
              @created="onCustomerUserCreation"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <FormProducts
        v-model="product_id"
        :product_id="product_id"
        :cols="3"
        :rules="{ required: !file }"
        :disabled="!!file"
        :lg="3"
      />
      <!--
      <FormItem
        v-model="brand"
        name="Brand"
        :cols="2"
        :rules="{ required: !file }"
        :disabled="!!file"
      />
      <FormItem
        v-model="model"
        name="Modello"
        :cols="2"
        :rules="{ required: !file }"
        :disabled="!!file"
      />
      -->

      <FormItem
        v-model="serial_code"
        name="Numero Seriale"
        :rules="{ required: !file && !imei }"
        :disabled="!!file"
        :lg="3"
      />
      <FormItem
        v-model="imei"
        name="IMEI/MSISDN"
        :rules="{ required: !file && !serial_code, max: 16 }"
        :disabled="!!file"
        :lg="3"
      />
    </v-row>

    <v-divider class="my-6" />

    <v-row>
      <FormItem
        v-model="status"
        type="select"
        :values="statuses"
        name="Stato"
        :disabled="!!file"
        :lg="3"
      />

      <FormItem
        v-model="activated_at"
        type="datepicker"
        name="Data attivazione"
        :disabled="!!file"
        :lg="3"
      />

      <FormItem
        v-model="assigned_at"
        type="datepicker"
        name="Data assegnazione"
        :disabled="!!file"
        :lg="3"
      />

      <FormCustomersAsset
        v-model="replacement_of"
        name="Dispositivo in sostituzione di"
        :customer_id="customer_id"
        :asset_id="replacement_of"
        :only-prefill="true"
        hint="Se l'asset è stato sostituito, specificare il rimpiazzo."
        clearable
        :lg="3"
      />

      <FormCustomersAsset
        v-model="associated_with"
        name="Dispositivo collegato"
        :customer_id="customer_id"
        :asset_id="associated_with"
        :associated_customer_user_id="customer_user_id"
        :associated_customer_location_id="customer_location_id"
        :only-prefill="true"
        @check-user-location = "onCheckUserLocation"
        hint="Specifiare l'asset collegato."
        clearable
        :lg="3"
      />

      <FormItem
        v-model="has_kasko"
        type="switch"
        name="Copertura Kasko"
        :disabled="!!file"
        :lg="3"
      />

      <FormItem
        v-model="is_spare"
        type="switch"
        name="Articolo di scorta"
        :disabled="!!file"
        :lg="3"
      />
    </v-row>
    <v-row>
      <!--
      <FormItem
        v-model="contract_start_at"
        type="datepicker"
        name="Inizio Contratto"
        :disabled="!has_kasko || !!file"
        :lg="3"
      />
      <FormItem
        v-model="contract_end_at"
        type="datepicker"
        name="Fine Contratto"
        :disabled="!has_kasko || !!file"
        :lg="3"
      />
      -->
      <FormItem
        v-model="notes"
        name="note"
        type="textarea"
        :disabled="!!file"
        :cols="9"
        :sm="9"
        :md="9"
        :lg="9"
        :xl="9"
      />
    </v-row>
    <v-divider :class="[edit ? 'transparent my-2' : 'my-6']" />
    <v-row v-if="!edit">
      <v-col>
        <p>
          E' possibile caricare massivamente più dispositivi procedendo al
          caricamento multiplo.
          <br />
          <a href="/static/templates/assets_import_template.xlsx">Scarica</a> il
          template personalizzato per questo chiente e caricalo dopo aver
          specificato le informazioni dei dispositivi.
        </p>
      </v-col>
      <v-col cols="5">
        <v-file-input
          v-model="file"
          label="Import massivo XLS"
          accept="application/vnd.ms-excel || application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          rule="size:100"
          show-size
          filled
          clearable
        ></v-file-input>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-alert v-if="isInvalidAssociatedUserLocation" type="warning">
        Per procedere all'invio, l'asset collegato deve essere dello stesso utente e nella stessa sede.
      </v-alert>
      <v-alert v-if="isAssociatedEqualCurrent" type="warning">
        Per procedere all'invio, l'asset collegato deve essere diverso da quello attuale.
      </v-alert>
      <v-alert v-if="isAssociatedEqualReplacement" type="warning">
        Per procedere all'invio, l'asset collegato deve essere diverso dall'asset in sostituzione.
      </v-alert>
      </v-col>
    </v-row>
  </Form>
</template>

<script>
import { createHelpers } from 'vuex-map-fields'
import { mapActions, mapMutations, mapState } from 'vuex'

import Form from '@components/forms/Form.vue'
import FormItem from '@components/forms/FormItem.vue'
import FormCustomers from '@components/forms/FormCustomers.vue'
import FormCustomersUsers from '@components/forms/FormCustomersUsers.vue'
import CustomersSubscriptionsSelect from '@components/customer_plans_subscriptions/CustomersSubscriptionsSelect.vue'
import CustomersLocationsSelect from '@/src/components/customer_locations/CustomersLocationsSelect.vue'
import FormCustomersAsset from '@components/forms/FormAssets.vue'
import CustomerUsersBtn from '@components/customers/CustomerUsersBtn.vue'
import FormProducts from '@components/forms/FormProducts.vue'

const { mapFields } = createHelpers({
  getterType: 'customersAssets/getCurrent',
  mutationType: 'customersAssets/SET_CURRENT_FIELDS',
})

export default {
  name: 'CustomerAssetsForm',
  components: {
    FormProducts,
    FormCustomersAsset,
    CustomersLocationsSelect,
    CustomersSubscriptionsSelect,
    FormCustomersUsers,
    CustomerUsersBtn,
    FormCustomers,
    FormItem,
    Form,
  },
  props: {
    edit: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: 'Crea Nuovo',
    },
    hideCustomer: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      associatedSameLocation: true,
      associatedSameUser: true,
    }
  },
  computed: {
    ...mapFields([
      'id',
      'brand',
      'model',
      'serial_code',
      'imei',
      'status',
      'has_kasko',
      'is_spare',
      'notes',
      'options',
      'customer_id',
      'customer_user_id',
      'customer_location_id',
      'customers_location_ids',
      'plans_subscription_id',
      'activated_at',
      'assigned_at',
      'associated_with',
      'contract_start_at',
      'contract_end_at',
      'replacement_of',
      'file',
      'product_id',
    ]),
    ...mapState('customersAssets', {
      id: (state) => state.current.id,
      loading: (state) => state.loading,
      statuses: (state) => state.statuses,
    }),
    isAssociated() {
      return !!this.associated_with
    },
    isInvalidAssociatedUserLocation() {
      return (!this.associatedSameLocation || !this.associatedSameUser) && this.isAssociated
    },
    isAssociatedEqualCurrent() {
      return this.id !== null && this.id === this.associated_with
    },
    isAssociatedEqualReplacement() {
      return this.replacement_of !== null && this.replacement_of === this.associated_with
    }
  },
  watch: {
    /* Every time the customer changes reset all the fields to avoid stagnant data */
    customer_id(id) {
      if (this.customer_id !== id) this.reset()
      this.customer_id = id
    },
  },
  beforeDestroy() {
    this.reset()
  },
  methods: {
    ...mapMutations('customersAssets', {
      reset: 'RESET_CURRENT',
      setCurrentValues: 'SET_CURRENT',
    }),
    ...mapActions('customersAssets', [
      'create',
      'update',
      'remove',
      'createBulk',
    ]),
    async onSubmit() {
      this.$emit('submit')
      if (this.file) {
        await this.createBulk()
        this.$emit('submitted')
        this.reset()
      } else {
        let data
        if (!this.edit) {
          data = await this.create()
        } else {
          data = await this.update()
        }

        this.$emit('submitted', data.id)
      }
    },
    async onCustomerUserCreation(id) {
      this.customer_user_id = id
    },
    onReset() {
      this.reset()
      this.$emit('resetted')
    },
    onCheckUserLocation(checkResult) {
      this.associatedSameUser = checkResult.sameUser
      this.associatedSameLocation = checkResult.sameLocation
    }
  },
}
</script>

<style scoped></style>
