<template>
  <Form
    :hide-reset="edit"
    :loading="loading"
    @submit="onSubmit"
    @reset="onReset"
  >
    <v-row class="my-4">
      <FormItem
        v-model="name"
        name="Titolo documento"
        rules="required"
        :cols="12"
        :sm="12"
        :md="4"
        :lg="4"
      />
      <!-- Disallow changing the linked file in editing -->
      <v-col v-if="!edit" :cols="12" :sm="12" :md="4" :lg="4">
        <v-file-input
          v-model="file"
          label="Seleziona file da caricare"
          rule="size:10000"
          show-size
          filled
          clearable
          hint="E' possibile caricare immagini, documenti ed archivi con una dimensione massima di 10MB."
          persistent-hint
        ></v-file-input>
      </v-col>
      <FormItem
        v-if="!currentCustomerId"
        v-model="visible"
        name="Visibile al cliente"
        type="switch"
        :cols="12"
        :sm="12"
        :md="4"
        :lg="4"
      />
    </v-row>
  </Form>
</template>

<script>
import { createHelpers } from 'vuex-map-fields'
import { mapActions, mapMutations, mapState } from 'vuex'

import Form from '@components/forms/Form.vue'
import FormItem from '@components/forms/FormItem.vue'
import { authComputed } from '@/src/state/helpers'

const { mapFields } = createHelpers({
  getterType: 'customersAttachments/getCurrent',
  mutationType: 'customersAttachments/SET_CURRENT_FIELDS',
})

export default {
  name: 'AttachmentForm',
  components: {
    FormItem,
    Form,
  },
  props: {
    edit: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: 'Crea Nuovo',
    },
  },
  data: () => ({
    statuses: [],
  }),
  computed: {
    ...authComputed,
    ...mapFields([
      'name',
      'filename',
      'description',
      'type',
      'file',
      'extra',
      'customer_id',
      'ticket_id',
      'visible',
    ]),
    ...mapState('customersAttachments', {
      id: (state) => state.current.id,
      types: (state) => state.types,
      loading: (state) => state.loading,
    }),
  },
  async mounted() {},
  methods: {
    ...mapMutations('customersAttachments', {
      reset: 'RESET_CURRENT',
      setCurrentValues: 'SET_CURRENT',
    }),
    ...mapActions('customersAttachments', ['create', 'update']),
    async onSubmit() {
      this.$emit('submit')
      // If the uploading user is customer bound make the file visible to customers
      // since they cannot se the "visible" control
      if (this.currentCustomerId) this.visible = true

      if (!this.edit) {
        await this.create()
      } else {
        await this.update()
      }

      this.$emit('submitted', this.id)

      this.reset()
    },
    onReset() {
      this.reset()
      this.$emit('resetted')
    },
  },
}
</script>

<style scoped></style>
