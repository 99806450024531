var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseTable',{ref:"table",attrs:{"title":"Piani Sottoscritti","actions":_vm.actions,"elevation":0,"headers":_vm.headers,"module":"plansSubscriptions"},scopedSlots:_vm._u([{key:"actions",fn:function(){return [(_vm.canUser('plans_subscriptions', 'create'))?_c('v-btn',{attrs:{"color":"green","dark":"","tile":"","flat":"","depressed":""},on:{"click":_vm.openModal}},[_vm._v(" Aggiungi ")]):_vm._e()]},proxy:true},{key:"item.plan.code?.",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":"primary","label":""}},[_vm._v(_vm._s(item.plan.code))])]}},{key:"item.code",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"label":""}},[_vm._v(_vm._s(item.code))])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.statusesMap[item.status])+" ")]}},{key:"item.date_start",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("momentOr")(item.date_start,'DD/MM/YYYY', '-'))+" ")]}},{key:"item.date_expiration",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("momentOr")(item.date_expiration,'DD/MM/YYYY', '-'))+" ")]}},{key:"item.options",fn:function(ref){
var item = ref.item;
return [_c('PlansOptionsQuickView',{attrs:{"item":item}})]}},{key:"footer",fn:function(){return [_c('v-dialog',{attrs:{"scrollable":"","max-width":"75%","fullscreen":_vm.$vuetify.breakpoint.mobile,"retain-focus":false},on:{"click:outside":_vm.modalClose},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_vm._v("Sottoscrizione Piano")]),_c('v-card-text',[(_vm.dialog)?_c('PlansSubscriptionsForm',{attrs:{"title":null,"edit":_vm.editMode},on:{"submitted":_vm.onSubmitted}}):_vm._e()],1)],1)],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }