<template>
  <BaseTable
    ref="table"
    title="Assets Cliente"
    :headers="headers"
    :actions="actions"
    :elevation="0"
    :exportable="canUser('customers.assets', 'download')"
    module="customersAssets"
  >
    <template v-slot:item.customers_user="{ item }">
      <span v-if="item.customers_user">
        {{ item.customers_user.last_name }}
        {{ item.customers_user.first_name }}
      </span>
      <em v-else>Non specificato</em>
    </template>
    <template v-slot:item.serial_code="{ item }">
      {{ item.serial_code | placeholder('-') }}
    </template>
    <template v-slot:item.assigned_at="{ item }">
      {{ item.assigned_at | momentOr('DD/MM/YYYY', '-') }}
    </template>
    <template v-slot:item.contract_start_at="{ item }">
      {{ item.contract_start_at | momentOr('DD/MM/YYYY', '-') }}
    </template>
    <template v-slot:item.contract_end_at="{ item }">
      {{ item.contract_end_at | momentOr('DD/MM/YYYY', '-') }}
    </template>
    <template v-slot:item.has_kasko="{ item }">
      {{ item.has_kasko ? 'SÌ' : 'NO' }}
    </template>
    <template v-slot:actions>
      <v-chip-group v-model="type" multiple column>
        <v-chip v-for="productList in productsList"
              :key="productList.type" :value="productList.type" filter outlined>
              {{ productList.type }} ({{ productList.type_count }})
              </v-chip>
      </v-chip-group>
      <v-btn
        v-if="canUser('customers.assets', 'create')"
        color="green"
        dark
        tile
        depressed
        @click="openModal"
      >
        Aggiungi
      </v-btn>
    </template>
    <template v-slot:footer>
      <v-dialog
        v-model="dialog"
        max-width="75%"
        :fullscreen="$vuetify.breakpoint.mobile"
        :retain-focus="false"
        @click:outside="modalClose"
      >
        <v-card>
          <v-card-title>Asset Cliente</v-card-title>
          <v-card-text>
            <CustomerAssetsForm
              v-if="dialog"
              :edit="editMode"
              :hide-customer="true"
              @submitted="onSubmitted"
            />
          </v-card-text>
        </v-card>
      </v-dialog>
    </template>
  </BaseTable>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import BaseTable from '@components/structure/base-table.vue'
import { createHelpers } from 'vuex-map-fields'
import { authComputed } from '@state/helpers.js'
import CustomerAssetsForm from '@components/customer_assets/CustomerAssetsForm.vue'

const { mapFields: mapCustomersAssetsFilters } = createHelpers({
  getterType: 'customersAssets/getFiltersFields',
  mutationType: 'customersAssets/SET_FILTER_FIELDS',
})

export default {
  name: 'CustomerAssets',
  components: { CustomerAssetsForm, BaseTable },
  data() {
    return {
      dialog: false,
      editMode: false,
      headers: [
        {
          text: 'Sottoscrizione',
          value: 'plans_subscription.code',
        },
        {
          text: 'Utente Cliente',
          value: 'customers_user',
        },
        {
          text: 'Stato',
          value: 'status',
        },
        {
          text: 'Brand',
          align: 'start',
          value: 'brand',
        },
        {
          text: 'Modello',
          value: 'model',
        },
        {
          text: 'Seriale',
          value: 'serial_code',
        },
        {
          text: 'IMEI',
          value: 'imei',
        },

        {
          text: 'Kasko',
          value: 'has_kasko',
        },
        {
          text: 'Data Assegnazione',
          value: 'assigned_at',
        },
        {
          text: 'Data Inserimento',
          value: 'created_at',
        },
      ],
      actions: [
        {
          label: 'Modifica asset',
          icon: 'mdi-pencil',
          color: 'primary',
          onItemCondition: () => this.canUser('customers.assets', 'update'),
          handler: this.openModal,
        },
        {
          label: 'Elimina asset',
          icon: 'mdi-delete',
          color: 'red',
          onItemCondition: () => this.canUser('customers.assets', 'delete'),
          handler: this.remove,
        },
      ],
    }
  },
  computed: {
    ...authComputed,
    ...mapState('customers', {
      customer: (state) => state.current,
    }),
    ...mapCustomersAssetsFilters(['customer_id', "type"]),
    ...mapState('customersAssets', {productsList: 'list'}),
  },

  watch: {
    type: {
      immediate: true,
      handler(val) {
        if (val) this.$refs.table.refresh()
      }
    }
  },

  async mounted() {
    this.setScopes(['product', 'customer_user', 'subscription'])
    this.customer_id = this.customer.id
    await this.getDropdownList({scopes: ['productsByType'], sortBy: ['type']})
  },
  beforeDestroy() {
    this.setScopes([])
    this.resetFilters()
  },
  methods: {
    ...mapActions('customersAssets', ['getItems', 'removeItem']),
    ...mapMutations('customersAssets', {
      setScopes: 'SET_SCOPES',
      setCurrent: 'SET_CURRENT',
      resetCurrent: 'RESET_CURRENT',
      resetFilters: 'RESET_FILTER_FIELDS',
    }),
    ...mapActions('customersAssets', ['getDropdownList']),
    openModal(item) {
      const startingPoint = item.id ? item : { customer_id: this.customer.id }
      // Understand if the modal was opened as editing or for a new sub
      const isEditing = !!startingPoint.id
      this.editMode = isEditing
      this.setCurrent(startingPoint)
      this.dialog = true
    },
    onSubmitted() {
      this.modalClose()
      this.$refs.table.refresh()
    },
    modalClose() {
      this.dialog = false
      this.editMode = false
      this.resetCurrent()
    },

    async remove(item) {
      const res = await this.$dialog.confirm({
        text:
          "Proseguire con l'eliminazione del asset?\n l'azione non è reversibile!",
        title: 'Attenzione',
      })

      if (!res) return

      await this.removeItem(item.id)
      this.$refs.table.refresh()
    },
  },
}
</script>
