<template>
  <Map height="100%" :markers="validMarkers" fit-bounds />
</template>

<script>
import Map from '@components/common/Map.vue'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

export default {
  name: 'CustomerMap',
  components: { Map },
  computed: {
    ...mapGetters('customersLocations', ['validMarkers']),
    ...mapState('customers', {
      customer: (state) => state.current,
    }),
  },
  mounted() {
    this.getDropdownList({
      filters: { customer_id: this.customer.id },
      scopes: ['marker'],
    })
  },
  beforeDestroy() {
    this.resetList()
  },
  methods: {
    ...mapActions('customersLocations', ['getDropdownList']),
    ...mapMutations('customersLocations', {
      resetList: 'RESET_LIST',
    }),
  },
}
</script>
