<template>
  <v-btn
    color="primary"
    class="py-7"
    block
    depressed
    tile
    :disabled="!canUser('customers.users', 'create')"
    @click="openModal"
  >
    <v-icon left>mdi-account-plus</v-icon>
    <small>
      Nuovo <br />
      utente
    </small>
    <v-dialog
      v-model="dialog"
      max-width="75%"
      :fullscreen="$vuetify.breakpoint.mobile"
      :retain-focus="false"
      @click:outside="closeModal"
    >
      <v-card>
        <v-card-title>Aggiungi Utente</v-card-title>
        <v-card-text>
          <CustomerUsersForm @submitted="onSubmitted" />
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-btn>
</template>

<script>
import CustomerUsersForm from '@components/customer_users/CustomerUsersForm.vue'
import { mapMutations } from 'vuex'
import { authComputed } from '@state/helpers.js'

export default {
  name: 'CustomerUsersBtn',
  components: {
    CustomerUsersForm,
  },
  props: {
    customer_id: {
      type: String,
      required: false,
    },
  },
  computed: {
    ...authComputed,
  },
  data() {
    return {
      dialog: false,
    }
  },
  beforeDestroy() {
    this.resetCurrent()
  },
  methods: {
    ...mapMutations('customersUsers', {
      setCurrent: 'SET_CURRENT',
      resetCurrent: 'RESET_CURRENT',
    }),
    openModal() {
      this.setCurrent({
        customer_id: this.customer_id,
      })
      this.dialog = true
    },
    closeModal() {
      this.resetCurrent()
      this.dialog = false
    },
    onSubmitted(id) {
      this.closeModal()
      this.$emit('created', id)
    },
  },
}
</script>
