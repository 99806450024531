<template>
  <BaseTable
    ref="table"
    title="Allegati"
    :headers="headers"
    :actions="actions"
    :elevation="0"
    :hide-title="hideTitle"
    module="customersAttachments"
  >
    <template v-slot:item.description="{ item }">
      {{ item.description | placeholder('-') }}
    </template>
    <template v-slot:item.size="{ item }">
      {{ item.size | filesize }}
    </template>
    <template v-slot:actions>
      <v-btn
        v-if="canUser('customers.attachments', 'create')"
        color="green"
        dark
        tile
        depressed
        @click="openModal"
      >
        Aggiungi
      </v-btn>
    </template>
    <template v-slot:footer>
      <v-dialog
        v-model="dialog"
        max-width="75%"
        :fullscreen="$vuetify.breakpoint.mobile"
        :retain-focus="false"
        @click:outside="modalClose"
      >
        <v-card>
          <v-card-title>Gestione Allegati</v-card-title>
          <v-card-text>
            <AttachmentForm
              v-if="dialog"
              :edit="editMode"
              @submitted="onSubmitted"
            />
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-dialog
        v-model="dialogPreview"
        max-width="75%"
        :fullscreen="$vuetify.breakpoint.mobile"
        :retain-focus="false"
      >
        <v-card v-if="dialogPreview">
          <v-card-title>Anteprima</v-card-title>
          <v-card-text v-if="type === 'image'">
            <v-img :src="dialogPreviewUrl" contain />
          </v-card-text>
          <v-card-text v-else>
            <DocumentViewer
              width="100%"
              height="800px"
              :file-url="dialogPreviewUrl"
              :engine="type"
            />
          </v-card-text>
        </v-card>
      </v-dialog>
    </template>
  </BaseTable>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import filesize from 'filesize'
import FileSaver from 'file-saver'
import BaseTable from '@components/structure/base-table.vue'
import { createHelpers } from 'vuex-map-fields'
import { authComputed } from '@state/helpers.js'
import AttachmentForm from '@components/attachments/AttachmentForm.vue'
import DocumentViewer from '@components/common/DocumentViewer.vue'

const { mapFields } = createHelpers({
  getterType: 'customersAttachments/getFiltersFields',
  mutationType: 'customersAttachments/SET_FILTER_FIELDS',
})

export default {
  name: 'CustomerAttachments',
  components: { DocumentViewer, AttachmentForm, BaseTable },
  filters: {
    filesize: function(value) {
      if (!value) return '-'
      return filesize(value)
    },
  },
  props: {
    showTickets: {
      type: Boolean,
      default: false,
    },
    hideTitle: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialog: false,
      dialogPreview: false,
      dialogPreviewUrl: null,
      type: null,
      editMode: false,
      headers: [
        {
          text: 'Nome',
          align: 'start',
          value: 'name',
        },
        {
          text: 'Descrizione',
          align: 'start',
          value: 'description',
        },
        {
          text: 'Nome file',
          align: 'start',
          value: 'filename',
        },
        {
          text: 'Dimensione file',
          align: 'start',
          value: 'size',
        },
        {
          text: 'Caricato da',
          value: 'uploaded_by',
        },
        {
          text: 'Data caricamento',
          value: 'created_at',
        },
      ],
      actions: [
        {
          label: 'Anteprima',
          icon: 'mdi-file-find',
          color: 'warning',
          onItemCondition: () => this.canUser('customers.attachments', 'read'),
          handler: this.preview,
        },
        {
          label: 'Scarica',
          icon: 'mdi-download',
          color: 'red',
          onItemCondition: () =>
            this.canUser('customers.attachments', 'download'),
          handler: this.download,
        },
        {
          label: 'Modifica',
          icon: 'mdi-pencil',
          color: 'primary',
          onItemCondition: () =>
            this.canUser('customers.attachments', 'update'),
          handler: this.openModal,
        },
        {
          label: 'Elimina',
          icon: 'mdi-delete',
          color: 'red',
          onItemCondition: () =>
            this.canUser('customers.attachments', 'delete'),
          handler: this.remove,
        },
      ],
    }
  },
  computed: {
    ...authComputed,
    ...mapState('customers', {
      customer: (state) => state.current,
    }),
    ...mapState('tickets', {
      ticket: (state) => state.current,
    }),
    ...mapFields(['customer_id', 'ticket_id']),
  },
  mounted() {
    const scopes = ['list']
    this.customer_id = this.customer.id

    if (this.showTickets) {
      this.ticket_id = this.ticket.id
    } else {
      this.ticket_id = null
      scopes.push('customer')
    }

    this.setScopes(scopes)
  },
  beforeDestroy() {
    this.setScopes([])
    this.resetFilters()
  },
  methods: {
    ...mapActions('customersAttachments', ['removeItem']),
    ...mapMutations('customersAttachments', {
      setCurrent: 'SET_CURRENT',
      setScopes: 'SET_SCOPES',
      resetCurrent: 'RESET_CURRENT',
      resetFilters: 'RESET_FILTER_FIELDS',
    }),

    getViewerByType(type) {
      switch (type) {
        case 'application/msword':
        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        case 'application/vnd.ms-excel':
        case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        case 'application/vnd.ms-powerpoint':
        case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
          return 'office'

        case 'application/pdf':
          return 'google'

        case 'image/bmp':
        case 'image/jpeg':
        case 'image/png':
        case 'image/gif':
        case 'image/svg+xml':
        case 'image/webp':
        case 'image/tiff':
          return 'image'

        default:
          return null
      }
    },

    preview(item) {
      this.dialogPreviewUrl = `${window.location.origin}${process.env.VUE_APP_BASE_URL}customers/attachments/download/${item.hashed}?t=${this.currentUser.token}`

      this.type = this.getViewerByType(item.type)

      if (!this.type) {
        // no compatible type found
        this.$dialog.warning({
          text: 'Anteprima  per questo tipo di file non disponibile!',
          title: 'Attenzione',
        })
      }

      this.dialogPreview = true
    },
    download(item) {
      const url = `${process.env.VUE_APP_BASE_URL}customers/attachments/download/${item.hashed}?t=${this.currentUser.token}`
      FileSaver.saveAs(url, item.filename)
    },
    async remove(item) {
      const res = await this.$dialog.confirm({
        text: "Proseguire con l'eliminazione?\nL'azione non è reversibile!",
        title: 'Attenzione',
      })

      if (!res) return

      await this.removeItem(item.id)
      await this.$refs.table.refresh()
    },

    openModal(item) {
      const startingPoint = item.id
        ? item
        : {
            customer_id: this.customer.id,
            ticket_id: this.showTickets ? this.ticket.id : null,
          }
      // Understand if the modal was opened as editing or for a new sub
      const isEditing = !!startingPoint.id
      this.editMode = isEditing
      this.setCurrent(startingPoint)
      this.dialog = true
    },
    onSubmitted() {
      this.modalClose()
      this.$refs.table.refresh()
    },
    modalClose() {
      this.dialog = false
      this.editMode = false
      this.resetCurrent()
    },
  },
}
</script>

<style scoped></style>
