<template>
  <v-col :cols="cols" :sm="sm" :md="md" :lg="lg" :xl="xl">
    <ValidationProvider v-slot="{ errors }" :name="name" :rules="rules">
      <v-autocomplete
        v-model="select"
        :chips="multi"
        :clearable="clearable"
        :deletable-chips="multi"
        :dense="dense"
        :disabled="disabled"
        :error-messages="errors"
        :filter="customFilter"
        :hint="hint"
        :items="list"
        :label="label || name"
        :loading="loading"
        :multiple="multi"
        :persistent-hint="persistentHint"
        :readonly="readonly"
        :search-input.sync="search"
        :small-chips="multi"
        hide-details
        filled
        @input="onInput"
      >
        <template v-slot:no-data>
          <v-list-item>
            <v-list-item-title> Nessun prodotto. </v-list-item-title>
          </v-list-item>
        </template>

        <template v-slot:selection="data">
          {{ data.item.brand }} {{ data.item.model }}
        </template>

        <template v-slot:item="{ item }">
          <v-list-item-content>
            <v-list-item-title>
              {{ item.brand }} {{ item.model }}
            </v-list-item-title>
            <v-list-item-subtitle>{{ item.type }} </v-list-item-subtitle>
            <v-list-item-subtitle v-if="item.part_number">
              {{ item.part_number }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </template>
      </v-autocomplete>
    </ValidationProvider>
  </v-col>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import { debounce } from 'lodash'
import { customFilterFromProps } from '@/src/utils/helper-functions'

export default {
  name: 'FormProducts',
  components: {},
  props: {
    chips: {
      default: false,
      type: Boolean,
    },
    clearable: {
      default: false,
      type: Boolean,
    },
    cols: {
      type: [String, Number],
      default: 12,
    },
    sm: {
      type: [String, Number],
      default: 6,
    },
    md: {
      type: [String, Number],
      default: 3,
    },
    lg: {
      type: [String, Number],
      default: 3,
    },
    xl: {
      type: [String, Number],
      default: 3,
    },
    deletableChips: {
      default: false,
      type: Boolean,
    },
    dense: {
      default: false,
      type: Boolean,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
    hint: {
      default: null,
      required: false,
      type: String,
    },
    label: {
      default: null,
      required: false,
      type: String,
    },
    multi: {
      default: false,
      type: Boolean,
    },
    name: {
      default: 'Prodotto',
      type: String,
    },
    persistentHint: {
      default: false,
      type: Boolean,
    },
    product_id: {
      default: null,
      type: [String, Array],
    },
    readonly: {
      default: false,
      type: Boolean,
    },
    prefillOnLoad: {
      default: false,
      type: Boolean,
    },
    rules: {
      default: '',
      required: false,
      type: [String, Object],
    },
    value: {
      default: null,
      type: [String, Array],
    },
  },
  data: () => ({
    loading: false,
    search: null,
    select: null,
  }),
  computed: {
    ...mapState('products', ['list']),
  },
  watch: {
    product_id: {
      immediate: true,
      handler: 'prefill',
    },
    search(val) {
      val && val !== this.select && this.querySelections(val)
    },
    /** When value is reset from the outside blank everything  */
    value(val) {
      if (!val) {
        this.select = this.multi ? [] : null
        this.search = null
        this.clearList()
      }
    },
  },
  beforeDestroy() {
    this.clearList()
  },
  methods: {
    ...mapActions('products', {
      searchProducts: 'getDropdownList',
    }),
    ...mapMutations('products', {
      clearList: 'RESET_LIST',
    }),
    async prefill() {
      if (this.prefillOnLoad) {
        this.loading = true
        await this.searchProducts({
          filters: {},
        })
        this.select = this.product_id || this.multi ? [] : null
        this.loading = false

        return
      }

      if (this.product_id) {
        this.loading = true
        await this.searchProducts({
          filters: {
            id: this.product_id,
          },
        })
        this.select = this.product_id
        this.loading = false
      } else {
        this.clearList()
        this.select = this.multi ? [] : null
      }
    },
    customFilter: customFilterFromProps(['model', 'brand', 'part_number']),
    async querySelections(query) {
      this.loading = true
      await this.searchProducts({
        filters: {
          query,
        },
      })
      this.loading = false
    },
    onInput: debounce(function(value) {
      this.$emit('input', value)
    }, 250),
  },
}
</script>
