<template>
  <v-container fluid>
    <CustomerDetail v-if="customer.id" @refresh="refresh" />

    <v-tabs v-model="tab" class="mt-6" background-color="transparent">
      <!-- piani sottoscritti -->
      <v-tab v-if="canUserSee('tickets')" href="#tickets">
        <v-icon left>mdi-face-agent</v-icon>
        Richieste & Tickets
      </v-tab>
      <v-tab-item v-if="canUserSee('tickets')" value="tickets">
        <CustomerTickets v-if="customer.id" />
      </v-tab-item>

      <v-tab v-if="canUserSee('plans_subscriptions')" href="#subscription">
        <v-icon left>mdi-playlist-check</v-icon>
        Piani ed Opzioni Sottoscritti
      </v-tab>
      <v-tab-item v-if="canUserSee('plans_subscriptions')" value="subscription">
        <CustomerPlansSubscriptions v-if="customer.id" />
      </v-tab-item>
      <!-- /piani sottoscritti-->

      <!-- utenze -->
      <v-tab v-if="canUserSee('customers.users')" href="#users">
        <v-icon left>mdi-account-group-outline</v-icon>
        Utenti
      </v-tab>
      <v-tab-item v-if="canUserSee('customers.users')" value="users">
        <CustomerUsers v-if="customer.id" />
      </v-tab-item>
      <!-- /utenze -->

      <!-- assets -->
      <v-tab v-if="canUserSee('customers.assets')" href="#assets">
        <v-icon left>mdi-devices</v-icon>
        Assets
      </v-tab>
      <v-tab-item v-if="canUserSee('customers.assets')" value="assets">
        <CustomerAssets v-if="customer.id" />
      </v-tab-item>
      <!-- /assets -->

      <v-tab v-if="canUserSee('customers.locations')" href="#locations">
        <v-icon left>mdi-office-building-marker</v-icon>
        Sedi e Gruppi
      </v-tab>
      <v-tab-item v-if="canUserSee('customers.locations')" value="locations">
        <CustomerLocations v-if="customer.id" class="mt-5" />
        <CustomerUsersGroups
          v-if="customer.id && canUserSee('customers.users_groups')"
          class="mt-5"
        />
      </v-tab-item>

      <v-tab v-if="canUserSee('customers.attachments')" href="#attachments">
        <v-icon left>mdi-file-document-multiple-outline</v-icon>
        Documentazione
      </v-tab>
      <v-tab-item
        v-if="canUserSee('customers.attachments')"
        value="attachments"
      >
        <CustomerAttachments v-if="customer.id" />
      </v-tab-item>
    </v-tabs>
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import CustomerDetail from '@components/customers/CustomerDetail.vue'
import CustomerLocations from '@components/customers/CustomerLocations.vue'
import { authComputed } from '@state/helpers.js'
import CustomerPlansSubscriptions from '@components/customers/CustomerPlansSubscriptions.vue'
import CustomerAttachments from '@components/customers/CustomerAttachments.vue'
import CustomerUsersGroups from '@components/customers/CustomerUsersGroups.vue'
import CustomerUsers from '@components/customers/CustomerUsers.vue'
import CustomerAssets from '@components/customers/CustomerAssets.vue'
import CustomerTickets from '@components/customers/CustomerTickets.vue'
import storeReset from '@mixins/storeReset'

export default {
  name: 'CustomerDetails',
  page: {
    title: 'Dettagli Cliente',
  },
  components: {
    CustomerTickets,
    CustomerUsers,
    CustomerUsersGroups,
    CustomerAttachments,
    CustomerPlansSubscriptions,
    CustomerDetail,
    CustomerLocations,
    CustomerAssets,
  },
  mixins: [storeReset],
  data: () => ({
    tabs: null,
  }),
  computed: {
    ...authComputed,
    ...mapState('customers', {
      customer: (state) => state.current,
    }),
    tab: {
      set(sub) {
        this.$router.replace({ query: { ...this.$route.query, sub } })
      },
      get() {
        return this.$route.query.sub
      },
    },
  },
  async mounted() {
    await this.refresh()
  },
  beforeDestroy() {
    this.storeReset({
      customers: ['current'],
    })
  },
  methods: {
    ...mapActions('customers', {
      setCurrent: 'setCurrent',
    }),
    async refresh() {
      await this.setCurrent(this.$route.params.id)
    },
  },
}
</script>

<style>
.v-tabs-items {
  background-color: transparent !important;
}
</style>
