<template>
  <BaseTable
    ref="table"
    title="Piani Sottoscritti"
    :actions="actions"
    :elevation="0"
    :headers="headers"
    module="plansSubscriptions"
  >
    <template v-slot:actions>
      <v-btn
        v-if="canUser('plans_subscriptions', 'create')"
        color="green"
        dark
        tile
        flat
        depressed
        @click="openModal"
      >
        Aggiungi
      </v-btn>
    </template>
    <template v-slot:item.plan.code?.="{ item }">
      <v-chip color="primary" label>{{ item.plan.code }}</v-chip>
    </template>

    <template v-slot:item.code="{ item }">
      <v-chip label>{{ item.code }}</v-chip>
    </template>

    <template v-slot:item.status="{ item }">
      {{ statusesMap[item.status] }}
    </template>

    <template v-slot:item.date_start="{ item }">
      {{ item.date_start | momentOr('DD/MM/YYYY', '-') }}
    </template>

    <template v-slot:item.date_expiration="{ item }">
      {{ item.date_expiration | momentOr('DD/MM/YYYY', '-') }}
    </template>

    <template v-slot:item.options="{ item }">
      <PlansOptionsQuickView :item="item" />
    </template>

    <template v-slot:footer>
      <v-dialog
        v-model="dialog"
        scrollable
        max-width="75%"
        :fullscreen="$vuetify.breakpoint.mobile"
        :retain-focus="false"
        @click:outside="modalClose"
      >
        <v-card>
          <v-card-title>Sottoscrizione Piano</v-card-title>
          <v-card-text>
            <PlansSubscriptionsForm
              v-if="dialog"
              :title="null"
              :edit="editMode"
              @submitted="onSubmitted"
            />
          </v-card-text>
        </v-card>
      </v-dialog>
    </template>
  </BaseTable>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import BaseTable from '@components/structure/base-table.vue'
import { createHelpers } from 'vuex-map-fields'
import { authComputed } from '@state/helpers.js'
import PlansSubscriptionsForm from '@components/plans/PlansSubscriptionsForm.vue'
import PlansOptionsQuickView from '@components/plans/PlansOptionsQuickView.vue'

const { mapFields: mapPlansSuscriptionFilters } = createHelpers({
  getterType: 'plansSubscriptions/getFiltersFields',
  mutationType: 'plansSubscriptions/SET_FILTER_FIELDS',
})

export default {
  name: 'CustomerPlansSubscriptions',
  components: { PlansOptionsQuickView, PlansSubscriptionsForm, BaseTable },
  data() {
    return {
      dialog: false,
      editMode: false,
      ctx: null,
      headers: [
        {
          text: '#ID',
          value: 'code',
        },
        {
          text: 'Codice Piano',
          align: 'start',
          value: 'plan.code',
        },
        {
          text: 'Stato',
          value: 'status',
        },
        {
          text: 'PIN',
          value: 'options.pin',
        },
        {
          text: 'Opzioni',
          value: 'options',
        },
        {
          text: 'Data Avvio',
          value: 'date_start',
        },
        {
          text: 'Data Scadenza',
          value: 'date_expiration',
        },
        {
          text: 'Inserito',
          value: 'created_at',
        },
      ],
      actions: [
        {
          label: 'Modifica',
          icon: 'mdi-pencil',
          color: 'primary',
          onItemCondition: () => this.canUser('plans_subscriptions', 'update'),
          handler: this.openModal,
        },
      ],
      statusesMap: {
        active: 'Attiva',
        expired: 'Scaduta',
        waiting: 'In attesa di validazione',
      },
    }
  },
  computed: {
    ...authComputed,
    ...mapState('customers', {
      customer: (state) => state.current,
    }),
    ...mapPlansSuscriptionFilters(['customer_id']),
  },
  mounted() {
    this.setScopes(['plan'])
    this.customer_id = this.customer.id
  },
  beforeDestroy() {
    this.setScopes([])
    this.resetFilters()
  },
  methods: {
    ...mapActions('plansSubscriptions', ['removeItem']),
    ...mapMutations('plansSubscriptions', {
      setScopes: 'SET_SCOPES',
      setCurrent: 'SET_CURRENT',
      resetCurrent: 'RESET_CURRENT',
      resetFilters: 'RESET_FILTER_FIELDS',
    }),

    openModal(item) {
      const startingPoint = item.id ? item : { customer_id: this.customer.id }
      // Understand if the modal was opened as editing or for a new sub
      const isEditing = !!startingPoint.id
      this.editMode = isEditing
      this.setCurrent(startingPoint)
      this.dialog = true
    },
    onSubmitted() {
      this.modalClose()
      this.$refs.table.refresh()
    },
    modalClose() {
      this.dialog = false
      this.editMode = false
      this.resetCurrent()
    },
  },
}
</script>
