<template>
  <BaseTable
    ref="table"
    title="Gestisci Sedi"
    :headers="headers"
    :actions="actions"
    :elevation="0"
    module="customersLocations"
  >
    <template v-slot:actions>
      <v-btn
        v-if="canUser('customers.locations', 'create')"
        color="green"
        dark
        tile
        depressed
        @click="openModal"
      >
        Aggiungi
      </v-btn>
    </template>
    <template v-slot:footer>
      <v-dialog
        v-model="dialog"
        max-width="75%"
        :fullscreen="$vuetify.breakpoint.mobile"
        :retain-focus="false"
        @click:outside="modalClose"
      >
        <v-card>
          <v-card-title>Sede Cliente</v-card-title>
          <v-card-text>
            <CustomerLocationsForm
              v-if="dialog"
              :edit="editMode"
              @submitted="onSubmitted"
            />
          </v-card-text>
        </v-card>
      </v-dialog>
    </template>
  </BaseTable>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import BaseTable from '@components/structure/base-table.vue'
import { createHelpers } from 'vuex-map-fields'
import CustomerLocationsForm from '@components/customer_locations/CustomerLocationsForm.vue'
import { authComputed } from '@state/helpers.js'

const { mapFields: mapCustomersLocationFilters } = createHelpers({
  getterType: 'customersLocations/getFiltersFields',
  mutationType: 'customersLocations/SET_FILTER_FIELDS',
})

export default {
  name: 'CustomerLocations',
  components: { CustomerLocationsForm, BaseTable },
  data() {
    return {
      dialog: false,
      editMode: false,
      headers: [
        {
          text: 'Nome',
          align: 'start',
          value: 'name',
        },
        {
          text: 'Indirizzo',
          value: 'address',
        },
        {
          text: 'Città',
          value: 'city',
        },
        {
          text: 'Provincia',
          value: 'province',
        },
        {
          text: 'Inserito',
          value: 'created_at',
        },
      ],
      actions: [
        {
          label: 'Modifica',
          icon: 'mdi-pencil',
          color: 'primary',
          onItemCondition: () => this.canUser('customers.locations', 'update'),
          handler: this.openModal,
        },
      ],
    }
  },
  computed: {
    ...authComputed,
    ...mapState('customers', {
      customer: (state) => state.current,
    }),
    ...mapCustomersLocationFilters(['customer_id']),
  },
  mounted() {
    this.customer_id = this.customer.id
  },
  beforeDestroy() {
    this.resetFilters()
  },
  methods: {
    ...mapMutations('customersLocations', {
      setCurrent: 'SET_CURRENT',
      resetCurrent: 'RESET_CURRENT',
      resetFilters: 'RESET_FILTER_FIELDS',
    }),

    openModal(item) {
      const startingPoint = item.id ? item : { customer_id: this.customer.id }
      // Understand if the modal was opened as editing or for a new sub
      const isEditing = !!startingPoint.id
      this.editMode = isEditing
      this.setCurrent(startingPoint)
      this.dialog = true
    },
    onSubmitted() {
      this.modalClose()
      this.$refs.table.refresh()
    },
    modalClose() {
      this.dialog = false
      this.editMode = false
      this.resetCurrent()
    },
  },
}
</script>
