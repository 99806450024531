<template>
  <Form
    :hide-reset="edit"
    :loading="loading"
    @submit="onSubmit"
    @reset="onReset"
  >
    <v-row dense>
      <FormItem
        v-model="name"
        rules="required"
        name="Nome"
        :cols="9"
        :md="6"
        :lg="6"
        :xl="6"
        dense
      />
    </v-row>
  </Form>
</template>

<script>
import { createHelpers } from 'vuex-map-fields'
import { mapActions, mapMutations, mapState } from 'vuex'

import Form from '@components/forms/Form.vue'
import FormItem from '@components/forms/FormItem.vue'

const { mapFields } = createHelpers({
  getterType: 'customersUsersGroups/getCurrent',
  mutationType: 'customersUsersGroups/SET_CURRENT_FIELDS',
})

export default {
  name: 'CustomerUserGroupForm',
  components: {
    FormItem,
    Form,
  },
  props: {
    edit: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: 'Crea Nuovo',
    },
  },
  computed: {
    ...mapFields(['name', 'enabled', 'customer_id', 'customer_location_id']),
    ...mapState('customersUsersGroups', {
      id: (state) => state.current.id,
      loading: (state) => state.loading,
    }),
  },
  methods: {
    ...mapMutations('customersUsersGroups', {
      reset: 'RESET_CURRENT',
      setCurrentValues: 'SET_CURRENT',
    }),
    ...mapActions('customersUsersGroups', ['create', 'update']),
    async onSubmit() {
      this.$emit('submit')

      if (!this.edit) {
        await this.create()
      } else {
        await this.update()
      }

      this.$emit('submitted', this.id)
    },
    onReset() {
      this.reset()
      this.$emit('resetted')
    },
  },
}
</script>

<style scoped></style>
